<template>
  <div class="page-content reviews-page">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h2 class="small-title">{{ $t('faq.your_questions') }}</h2>
          <div class="faq-item">
            <p class="faq-question">{{ $t('faq.question_1') }}</p>
            <p><strong>{{ $t('faq.answer_1_s') }}</strong></p>
            <p>{{ $t('faq.answer_1') }}</p>
          </div>
          <div class="faq-item">
            <p class="faq-question">{{ $t('faq.question_2') }}</p>
            <p><strong>{{ $t('faq.answer_2_s') }}</strong></p>
            <p>{{ $t('faq.answer_2') }}</p>
            <p>{{ $t('faq.answer_2_but') }}</p>
          </div>
          <div class="faq-item">
            <p class="faq-question">{{ $t('faq.question_3') }}</p>
            <p>{{ $t('faq.answer_3_1') }}</p>
            <p>{{ $t('faq.answer_3_2') }}</p>
            <p>{{ $t('faq.answer_3_3') }}</p>
            <p>{{ $t('faq.answer_3_4') }}</p>
            <p>{{ $t('faq.answer_3_5') }}</p>
            <p>{{ $t('faq.answer_3_6') }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <h2 class="small-title mt-4 mt-md-0">{{ $t('faq.clients_feedback') }}</h2>
          <CoolLightBox
            :items="galleryImages"
            :index="index"
            @close="index = null">
          </CoolLightBox>
          <div v-masonry transition-duration="0.6s" item-selector=".mansory-item" class="row gallery-wrapper">
              <div v-masonry-tile class="mansory-item col-6 col-lg-4" v-for="(item, imageIndex) in gallerySmallImages" v-bind:key="imageIndex" @click="index = imageIndex">
                <div class="card">
                  <img :src="item" />
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {VueMasonryPlugin} from 'vue-masonry';
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
Vue.use(VueMasonryPlugin)

export default {
  components: {
    CoolLightBox
  },
  data: function () {
    return {
      index: null,
      gallerySmallImages: [
        '/assets/images/reviews/08.jpg',
        '/assets/images/reviews/10.jpg',
        '/assets/images/reviews/22.jpg',
        '/assets/images/reviews/13.jpg',
        '/assets/images/reviews/20.jpg',
        '/assets/images/reviews/21.jpg',
        '/assets/images/reviews/07.jpg',
        '/assets/images/reviews/19.jpg',
        '/assets/images/reviews/09.jpg',
        '/assets/images/reviews/10.jpg',
        '/assets/images/reviews/11.jpg',
        '/assets/images/reviews/24.jpg'
      ]
    }
  },
  computed: {
    galleryImages() {
      let galImage = [];
      for (let i = 1; i < 10; i++) {
        galImage.push('/assets/images/reviews/0' + i + '.jpg')
      }
      for (let i = 10; i <= 33; i++) {
        galImage.push('/assets/images/reviews/' + i + '.jpg')
      }
      return galImage;
    },
  }
}
</script>

<style lang="scss">
.faq-item {
  padding-bottom: 1em;
  padding-top: 1em;
  @media screen and (min-width: 768px) {
    padding-bottom: 25px;
    padding-top: 25px;
  }
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  > p:last-child {
    margin-bottom: 0;
  }
}
.faq-question {
  color: #C9AD81;
  font-weight: bold;
  font-size: 1.2em;
}
.reviews-page {
  padding-top: 40px;
  img {
    max-width: 100%;
  }
}
.gallery-wrapper img {
  filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.1));
}
.gallery-wrapper {
  .col-6, .col-lg-4 {
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px;
  }
}
</style>
